import PropTypes from 'prop-types';
import './Modal.css';
import { useFormik, Formik, Form } from 'formik';
import { FocusError } from 'focus-formik-error';
import * as yup from 'yup';
import { CadenceList } from '../../Utility';
import { useMsal } from '@azure/msal-react';

const Modal = ({ hide, AddNotification, applications }) => {
  const { instance } = useMsal();
  const initialValues = {
    ApplicationId: '',
    Name: '',
    Cadence: '',
    Description: '',
  };
  const account = instance.getActiveAccount();
  const validationSchema = yup.object().shape({
    ApplicationId: yup.string().required('Select an application'),
    Name: yup
      .string('Enter the Notification Name')
      .matches(
        /[A-Z]/,
        'Notification Name must contain at least one capital letter'
      )
      .required('Notification Name is required'),
    Cadence: yup
      .string('Select a Cadence for Notification')
      .required('Notification Cadence is required.'),

    Description: yup
      .string('Enter the Notification Description')
      .matches(
        /[A-Z]/,
        'Notification Description must contain at least one capital letter'
      )
      .required('Notification Description is required.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const notificationData = {
        applicationId: values.ApplicationId,
        name: values.Name,
        description: values.Description,
        type: 'Email',
        frequency: values.Cadence,
        createdBy: account.username,
      };
      await AddNotification(notificationData);
    },
  });

  return (
    <>
      <div className='ModalContainer'>
        <div className='modal'>
          <button
            className='modalX'
            data-testid={'AddNotifXButton'}
            onClick={hide}
          >
            X
          </button>
          <div className='ModalTitle'>
            <h1 className='modalBold'> Enter a Notification to Add </h1>
          </div>
          <div className='modalBody'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {() => (
                <Form onSubmit={formik.handleSubmit} method='post'>
                  <FocusError
                    formik={formik}
                    offset={0}
                    align={'top'}
                    focusDelay={200}
                    ease={'linear'}
                    duration={1000}
                  />
                  <label
                    htmlFor='selectApplication'
                    className='visually-hidden'
                  >
                    Select Application:
                  </label>
                  <select
                    id='selectApplication'
                    data-testid='selectApplication'
                    className='modalInput'
                    name='ApplicationId'
                    value={formik.values.ApplicationId}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Application</option>
                    {applications.map((app, index) => (
                      <option key={index} value={app.Id}>
                        {app.ApplicationName}
                      </option>
                    ))}
                  </select>
                  <div className='error-text'>
                    {formik.touched.ApplicationId &&
                      formik.errors.ApplicationId}
                  </div>
                  <label htmlFor='notificationName' className='visually-hidden'>
                    Notification Name:
                  </label>
                  <input
                    className='modalInput'
                    type='text'
                    id='notificationName'
                    name='Name'
                    placeholder='Notification Name'
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    maxLength={25}
                  />
                  <div className='error-text'>
                    {formik.touched.Name && formik.errors.Name}
                  </div>
                  <label
                    htmlFor='notificationDescription'
                    className='visually-hidden'
                  >
                    Notification Description:
                  </label>
                  <input
                    className='modalInput'
                    type='text'
                    id='notificationDescription'
                    name='Description'
                    placeholder='Notification Description'
                    value={formik.values.Description}
                    onChange={formik.handleChange}
                    maxLength={50}
                  />
                  <div className='error-text'>
                    {formik.touched.Description && formik.errors.Description}
                  </div>
                  <label htmlFor='selectCadence' className='visually-hidden'>
                    Select Cadence:
                  </label>
                  <select
                    className='modalInput'
                    data-testid='selectCadence'
                    id='selectCadence'
                    value={formik.values.Cadence}
                    name='Cadence'
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Cadence</option>
                    {CadenceList.map((cadence, index) => (
                      <option key={index} value={cadence}>
                        {cadence}
                      </option>
                    ))}
                  </select>
                  <div className='error-text'>
                    {formik.touched.Cadence && formik.errors.Cadence}
                  </div>
                  <div className='Footer'>
                    <button
                      className='CancelButton'
                      data-testid={'AddNotifCancelButton'}
                      type='button'
                      onClick={hide}
                    >
                      Cancel
                    </button>
                    <button
                      className='EditButton'
                      data-testid={'AddNotifSubmitButton'}
                      type='submit'
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  hide: PropTypes.func.isRequired,
  AddNotification: PropTypes.func.isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      ApplicationName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Modal;
