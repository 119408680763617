import { useState } from 'react';
import PropTypes from 'prop-types';
import './AdminList.css';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import EditModal from '../Modals/EditModal';
import DeleteModal from '../Modals/DeleteModal';

const AdminList = ({
  notificationList,
  EditNotification,
  toggleEditModal,
  isEditModalOpen,
  isDeleteShowing,
  toggleDeleteModal,
  DeleteNotification,
}) => {
  const [selectedNotification, setSelectedNotification] = useState([]);

  const setEditNotification = (notification) => {
    setSelectedNotification(notification);
    toggleEditModal();
  };

  const setDeleteNotification = (notification) => {
    setSelectedNotification(notification);
    toggleDeleteModal();
  };

  return (
    <div>
      {isEditModalOpen ? (
        <EditModal
          selectedNotification={selectedNotification}
          Close={toggleEditModal}
          EditNotif={EditNotification}
        />
      ) : null}

      {isDeleteShowing ? (
        <DeleteModal
          selectedNotification={selectedNotification}
          Close={toggleDeleteModal}
          DeleteNotification={DeleteNotification}
        />
      ) : null}

      <table className='AdminTable'>
        <thead className='TableHead'>
          <tr>
            <th data-testid={'AppHeader'}>Application</th>
            <th data-testid={'NotifHeader'}>Notification</th>
            <th>Cadence</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className='TableBody'>
          {notificationList.map((notification, index) => (
            <tr key={index}>
              <td>{notification.ApplicationName}</td>
              <td>{notification.Name}</td>
              <td className='cadences'>{notification.Frequency}</td>
              <td className='ActionButtons'>
                <button
                  className='EditBtn'
                  data-testid={`edit ${notification.Name}`}
                  onClick={() => setEditNotification(notification)}
                >
                  <FiEdit size='20px' />
                </button>
                <button
                  className='DeleteBtn'
                  data-testid={`delete ${notification.Name}`}
                  onClick={() => setDeleteNotification(notification)}
                >
                  <AiOutlineDelete size='20px' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

AdminList.propTypes = {
  notificationList: PropTypes.array.isRequired,
  EditNotification: PropTypes.func.isRequired,
  toggleEditModal: PropTypes.func.isRequired,
  isEditModalOpen: PropTypes.bool.isRequired,
  isDeleteShowing: PropTypes.bool.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  DeleteNotification: PropTypes.func.isRequired,
};

export default AdminList;
