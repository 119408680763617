import PropTypes from 'prop-types';
import './EditModal.css';
import { CadenceList, TypeList } from '../../Utility';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMsal } from '@azure/msal-react';

const EditModal = ({ Close, EditNotif, selectedNotification }) => {
  const initialValues = {
    NotificationName: selectedNotification.Name,
    NotificationType: selectedNotification.Type,
    NotificationCadence: selectedNotification.Frequency,
    NotificationDescription: selectedNotification.Description,
  };

  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const validationSchema = yup.object().shape({
    NotificationName: yup
      .string('Enter the Updated Notification Name')
      .matches(
        /[A-Z]/,
        'Notification Name must contain at least one capital letter'
      )
      .required('Notification Name is required'),
    NotificationType: yup
      .string('Select a Type for Notification')
      .required('Type is required'),
    NotificationCadence: yup
      .string('Select a Cadence for Notification')
      .required('Notification Cadence is required.'),

    NotificationDescription: yup
      .string('Enter the Notification Description')
      .matches(
        /[A-Z]/,
        'Notification Description must contain at least one capital letter'
      )
      .required('Notification Description is required.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const updatedData = {
        id: selectedNotification.Id,
        name: values.NotificationName,
        description: values.NotificationDescription,
        type: values.NotificationType,
        frequency: values.NotificationCadence,
        updatedBy: account.username,
      };
      await EditNotif(updatedData);
    },
  });

  return (
    <div className='ModalOverlay'>
      <div className='EditModalContent'>
        <button className='CloseButton' data-testid={`Xbtn`} onClick={Close}>
          X
        </button>
        <h1 className='EditTitle'>Notification Editor</h1>
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor='NotifName' className='visually-hidden'>
            NotificationName:
          </label>
          <input
            className='EditText'
            type='text'
            id='NotifName'
            name='NotificationName'
            value={formik.values.NotificationName}
            onChange={formik.handleChange}
            maxLength={25}
          />
          {formik.touched.NotificationName &&
            formik.errors.NotificationName && (
              <div className='ErrorMessage'>
                {formik.errors.NotificationName}
              </div>
            )}
          <label htmlFor='notifDescription' className='visually-hidden'>
            NotificationDescription:
          </label>
          <input
            className='EditText'
            type='text'
            id='notifDescription'
            name='NotificationDescription'
            value={formik.values.NotificationDescription}
            onChange={formik.handleChange}
            maxLength={50}
          />
          {formik.touched.NotificationDescription &&
            formik.errors.NotificationDescription && (
              <div className='ErrorMessage'>
                {formik.errors.NotificationDescription}
              </div>
            )}
          <label htmlFor='selectType' className='visually-hidden'>
            Select Type:
          </label>
          <select
            className='modalInput'
            id='selectType'
            name='NotificationType'
            value={formik.values.NotificationType}
            onChange={formik.handleChange}
          >
            <option value=''>{selectedNotification.Type}</option>
            {TypeList.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          {formik.touched.NotificationType &&
            formik.errors.NotificationType && (
              <div className='ErrorMessage'>
                {formik.errors.NotificationType}
              </div>
            )}
          <label htmlFor='Cadence' className='visually-hidden'>
            Select Cadence:
          </label>
          <select
            className='modalInput'
            id='Cadence'
            name='NotificationCadence'
            value={formik.values.NotificationCadence}
            onChange={formik.handleChange}
          >
            <option value=''>{selectedNotification.Frequency}</option>
            {CadenceList.map((cadence, index) => (
              <option key={index} value={cadence}>
                {cadence}
              </option>
            ))}
          </select>
          {formik.touched.NotificationCadence &&
            formik.errors.NotificationCadence && (
              <div className='ErrorMessage'>
                {formik.errors.NotificationCadence}
              </div>
            )}

          <div className='EditFooter'>
            <button
              className='CancelButton'
              type='button'
              data-testid={`Cancelbtn`}
              onClick={Close}
            >
              Cancel
            </button>
            <button
              className='EditButton'
              data-testid={`Submitbtn`}
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditModal.propTypes = {
  Close: PropTypes.func.isRequired,
  EditNotif: PropTypes.func.isRequired,
  selectedNotification: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Type: PropTypes.string.isRequired,
    Frequency: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditModal;
