import { useFormik, Formik, Form } from 'formik';
import { FocusError } from 'focus-formik-error';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import './AddAppModal.css';
import { useMsal } from '@azure/msal-react';

const AddAppModal = ({ hide, AddApplication }) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const initialValues = {
    ApplicationName: '',
    ApplicationDescription: '',
  };

  const validationSchema = yup.object().shape({
    ApplicationName: yup
      .string('Enter Application Name')
      .matches(
        /[A-Z]/,
        'Application Name must contain at least one capital letter'
      )
      .required('Application Name Required'),
    ApplicationDescription: yup
      .string('Enter Application Description')
      .matches(
        /[A-Z]/,
        'Application Description must contain at least one capital letter'
      )
      .required('Application Description Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const applicationData = {
        applicationName: values.ApplicationName,
        description: values.ApplicationDescription,
        createdBy: account.username,
      };
      await AddApplication(applicationData);
    },
  });

  return (
    <>
      <div className='ModalContainer'>
        <div className='modal'>
          <button className='modalX' data-testid={'AddAppXBtn'} onClick={hide}>
            {' '}
            X{' '}
          </button>
          <div className='ModalTitle'>
            <h1 className='modalBold'> Enter an Application to Add </h1>
          </div>
          <div className='modalBody'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {() => (
                <Form onSubmit={formik.handleSubmit} method='post'>
                  <FocusError
                    formik={formik}
                    offset={0}
                    align={'top'}
                    focusDelay={200}
                    ease={'linear'}
                    duration={1000}
                  />
                  <label htmlFor='ApplicationName' className='visually-hidden'>
                    Application Name:
                  </label>
                  <input
                    className='modalInput'
                    type='text'
                    id='ApplicationName'
                    name='ApplicationName'
                    placeholder='Application Name'
                    value={formik.values.ApplicationName}
                    onChange={formik.handleChange}
                    maxLength={25}
                  />
                  <div className='error-text'>
                    {formik.touched.ApplicationName &&
                      formik.errors.ApplicationName}
                  </div>
                  <label
                    htmlFor='ApplicationDescription'
                    className='visually-hidden'
                  >
                    Application Description:
                  </label>
                  <input
                    className='modalInput'
                    type='text'
                    id='ApplicationDescription'
                    name='ApplicationDescription'
                    placeholder='Application Description'
                    value={formik.values.ApplicationDescription}
                    onChange={formik.handleChange}
                    maxLength={50}
                  />
                  <div className='error-text'>
                    {formik.touched.ApplicationDescription &&
                      formik.errors.ApplicationDescription}
                  </div>
                  <div className='Footer'>
                    <button
                      className='CancelButton'
                      data-testid={'AddAppCancelBtn'}
                      type='button'
                      onClick={hide}
                    >
                      {' '}
                      Cancel{' '}
                    </button>
                    <button
                      className='EditButton'
                      data-testid='AddAppSubmitBtn'
                      type='submit'
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

AddAppModal.propTypes = {
  hide: PropTypes.func.isRequired,
  AddApplication: PropTypes.func.isRequired,
};

export default AddAppModal;
