import './Loading.css';
function Loading() {
  return (
    <>
      <div className='loader'>
        <img
          src='/tangofav.png'
          alt='Loading'
          className='fa fa-spin logo-spin'
        />
        <p>Please wait while we are handling your request.</p>
      </div>
    </>
  );
}

export default Loading;
