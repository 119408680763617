import axios from 'axios';
import { get } from 'lodash';
import {
  AppEnvironmentConfig,
  CloudEnvironmentConfig,
} from './configs/appConfigs';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

export const CadenceList = ['1d', '2d', '1w', '2w', '1m', '2m'];

export const TypeList = ['Email'];

export const GetConfigFileName = () => {
  let fileName = '/config';

  if (IS_DEV) {
    const selectedEndpointEnv = REACT_APP_ENDPOINT_ENV;
    switch (selectedEndpointEnv) {
      case CloudEnvironmentConfig.SB:
        fileName = `${fileName}-sb`;
        break;
      case CloudEnvironmentConfig.QA:
        fileName = `${fileName}-qa`;
        break;
      case CloudEnvironmentConfig.DEV:
        fileName = `${fileName}-dev`;
        break;
      default:
        throw new Error('Unsupported environment');
    }
  }

  fileName = `${fileName}.json`;

  return fileName;
};

export const IS_DEV =
  get(process.env, 'REACT_APP_NODE_ENV', '') === AppEnvironmentConfig.DEV;

//Applied endpoint for localhost -> can be changed via CLI e.g. set REACT_APP_ENDPOINT_ENV=SB
export const REACT_APP_ENDPOINT_ENV = get(
  process.env,
  'REACT_APP_ENDPOINT_ENV',
  ''
);
