import { RiAccountCircleLine } from 'react-icons/ri';
import './Navbar.css';

function Navbar() {
  return (
    <nav className='nav'>
      <a href='/' className='title'>
        {' '}
        <img className='logo' src='/tangoLogo.png' alt='logo' />{' '}
      </a>
      <div>
        <RiAccountCircleLine
          className='accountIcon'
          style={{}}
          size='50px'
          color='white'
          cursor='pointer'
        />
      </div>
    </nav>
  );
}

export default Navbar;
