import PropTypes from 'prop-types';
import './DeleteModal.css';

function DeleteModal({ selectedNotification, Close, DeleteNotification }) {
  const handleDelete = () => {
    DeleteNotification(selectedNotification.Id);
    Close();
  };
  return (
    <div className='ModalOverlay'>
      <div className='DeleteModalContent'>
        <button className='CloseButton' data-testid={`X`} onClick={Close}>
          X
        </button>
        <h1 className='DeleteTitle'>Are You Sure You Want To Delete:</h1>
        <h2 className='DeleteText'>{selectedNotification.Name}</h2>
        <div className='DeleteFooter'>
          <button
            className='CancelButton'
            data-testid={`Cancel`}
            onClick={Close}
          >
            {' '}
            Cancel{' '}
          </button>
          <button
            className='DeleteButton'
            data-testid={`delete`}
            onClick={handleDelete}
          >
            {' '}
            Delete{' '}
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteModal.propTypes = {
  selectedNotification: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  Close: PropTypes.func.isRequired,
  DeleteNotification: PropTypes.func.isRequired,
};

export default DeleteModal;
