export const updateConfigs = (data) => {
  configs = data;
};

export let configs = {
  REACT_APP_CLIENT_ID: '',
  REACT_APP_LOGIN_URL: '',
  VITE_API_BASEURL: '',
  REACT_APP_NOTIFICATION_MANAGER_API: '',
};
