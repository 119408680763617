export const AppEnvironmentConfig = {
  DEV: 'development',
  PROD: 'production',
};

export const CloudEnvironmentConfig = {
  SB: 'SB',
  QA: 'QA',
  DEV: 'DEV',
};
