import PropTypes from 'prop-types';
import './UserList.css';

const UserList = ({ mergeList, subscribe, unsubscribe }) => {
  return (
    <div>
      <table className='UserTable'>
        <thead className='UserHeader'>
          <tr>
            <th>Application</th>
            <th>Notification</th>
            <th>Cadence</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className='UserBody'>
          {mergeList.map((subscription, index) => (
            <tr key={index} data-testid={subscription.Name}>
              <td>{subscription.ApplicationName}</td>
              <td>{subscription.Name}</td>
              <td className='frequencies'>{subscription.Frequency}</td>

              <td className='subUnSubBtns'>
                {subscription.isSubscribed ? (
                  <button
                    className='UnsubBtn'
                    data-testid={`unsubscribe ${subscription.Name}`}
                    onClick={() => unsubscribe(subscription.NotificationUid)}
                  >
                    Unsubscribe
                  </button>
                ) : (
                  <button
                    className='SubBtn'
                    data-testid={`subscribe ${subscription.Name}`}
                    onClick={() => subscribe(subscription.NotificationUid)}
                  >
                    Subscribe
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

UserList.propTypes = {
  mergeList: PropTypes.arrayOf(
    PropTypes.shape({
      ApplicationName: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Frequency: PropTypes.string.isRequired,
      NotificationUid: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      isSubscribed: PropTypes.bool.isRequired,
    })
  ).isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
};

export default UserList;
