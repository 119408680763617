import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import './Admin.css';
import Modal from '../../components/Modals/Modal';
import AddAppModal from '../../components/Modals/AddAppModal';
import AdminList from '../../components/AdminList/AdminList';
import {
  postNotification,
  postApplication,
  getNotification,
  getApplication,
  putNotification,
  deleteNotification,
} from '../../API';
import Loading from '../../components/Loading/Loading';

const Admin = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [isAddAppModalShowing, setIsAddAppModalShowing] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteShowing, setDeleteShowing] = useState(false);
  const [error, setError] = useState('');

  const { instance } = useMsal();
  function toggle() {
    setIsShowing(!isShowing);
  }

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteShowing(!isDeleteShowing);
  };

  function toggleAddAppModal() {
    setIsAddAppModalShowing(!isAddAppModalShowing);
  }

  const AddApplication = async (applicationData) => {
    try {
      setLoading(true);
      await postApplication(instance, applicationData);
      await fetchApplication();
      setLoading(false);
      toggleAddAppModal();
    } catch (error) {
      setLoading(false);
      toggleAddAppModal();
      setError(error.message);
      console.log(error.message);
    }
  };

  const AddNotification = async (notificationData) => {
    try {
      setLoading(true);
      await postNotification(instance, notificationData);
      await fetchNotification();
      setLoading(false);
      toggle();
    } catch (error) {
      setLoading(false);
      toggle();
      setError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchNotification(); // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchApplication();
    };

    fetchData(); // eslint-disable-next-line
  }, [instance]);

  async function fetchNotification() {
    try {
      setLoading(true);
      const notifications = await getNotification(instance);
      setNotificationList(notifications);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error.message);
    }
  }

  const fetchApplication = async () => {
    try {
      setLoading(true);
      const application = await getApplication(instance);
      setApplications(application);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error.message);
    }
  };

  const EditNotification = async (updatedData) => {
    try {
      setLoading(true);
      await putNotification(instance, updatedData);
      await fetchNotification();
      setLoading(false);
      toggleEditModal();
    } catch (error) {
      setLoading(false);
      toggleEditModal();
      setError(error.message);
      console.log('Error updating notification:', error.message);
    }
  };

  const DeleteNotification = async (id) => {
    try {
      setLoading(true);
      await deleteNotification(instance, id);
      await fetchNotification();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {error && <div className='error-message'>{error}</div>}
          <h1 className='adminTitle'>
            Applications & Notifications Admin Settings
          </h1>
          <div className='notTitle'>
            <h2 className='instructions'> Instructions: </h2>
            <p className='adminText'>
              {' '}
              1. To add a new Application or Notification please use the Add
              Application or Add Notification Button{' '}
            </p>
            <p className='adminText'>
              {' '}
              2. To Edit a Notification, use the Edit button next to the desired
              Notification{' '}
            </p>
          </div>
          {notificationList?.length > 0 ? (
            <AdminList
              notificationList={notificationList}
              EditNotification={EditNotification}
              DeleteNotification={DeleteNotification}
              toggleEditModal={toggleEditModal}
              toggleDeleteModal={toggleDeleteModal}
              isEditModalOpen={isEditModalOpen}
              isDeleteShowing={isDeleteShowing}
            />
          ) : (
            <div
              style={{
                textAlign: 'center',
                marginTop: '60px',
                marginBottom: '60px',
                fontWeight: 'bold',
              }}
            >
              No Notifications available
            </div>
          )}

          <ButtonGroup toggle={toggle} toggleAddAppModal={toggleAddAppModal} />
          {isShowing ? (
            <Modal
              isShowing={isShowing}
              hide={toggle}
              AddNotification={AddNotification}
              applications={applications}
            />
          ) : null}
          {isAddAppModalShowing ? (
            <AddAppModal
              hide={toggleAddAppModal}
              AddApplication={AddApplication}
            />
          ) : null}
        </>
      )}
      ;
    </>
  );
};

export default Admin;
