import PropTypes from 'prop-types';
import './ButtonGroup.css';
import { IoIosAddCircleOutline } from 'react-icons/io';

function ButtonGroup({ toggle, toggleAddAppModal }) {
  return (
    <div className='addBtns'>
      <button
        className='addApp'
        data-testid={'addApplicationBtn'}
        onClick={toggleAddAppModal}
      >
        <IoIosAddCircleOutline style={{}} size='25px' />
        Application
      </button>
      <button
        className='addNotification'
        data-testid={'addNotificationBtn'}
        onClick={toggle}
      >
        <IoIosAddCircleOutline style={{}} size='25px' />
        Notification
      </button>
    </div>
  );
}

ButtonGroup.propTypes = {
  toggle: PropTypes.func.isRequired,
  toggleAddAppModal: PropTypes.func.isRequired,
};

export default ButtonGroup;
