import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import './User.css';
import UserList from '../../components/UserList/UserList';
import Loading from '../../components/Loading/Loading';
import {
  postSubscription,
  getSubscription,
  deleteSubscription,
  getNotification,
} from '../../API';

function User() {
  const [mergeList, setMergeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  async function fetchNotifications() {
    setLoading(true);
    let subscriptionList = [];
    try {
      const notifications = await getNotification(instance);

      try {
        subscriptionList = await getSubscription(instance);
      } catch (subError) {
        if (subError.response && subError.response.status === 404) {
          console.log('Subscription list not found:', subError.message);
          // Continue with an empty subscriptionList if it's a 404 error
        } else {
          // Re-throw the error if it's not a 404, to be caught by the outer catch block
          throw subError;
        }
      }

      const newArray = notifications?.map((noti) => ({
        ...noti,
        isSubscribed: subscriptionList.some(
          (sub) => sub.NotificationUid === noti.NotificationUid
        ),
      }));
      setMergeList(newArray);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setMergeList([]);
    } finally {
      setLoading(false);
    }
  }

  const subscribe = async (notiUId) => {
    try {
      setLoading(true);
      await postSubscription(instance, notiUId);
      await fetchNotifications();
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const unsubscribe = async (notiUId) => {
    try {
      setLoading(true);
      await deleteSubscription(instance, notiUId);
      await fetchNotifications();
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchNotifications(); // eslint-disable-next-line
  }, [instance]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h1 className='userTitle'>Subscription Account Settings</h1>
          {mergeList.length > 0 ? (
            <>
              <h2 className='userText'>
                Let Us Know Which Notifications You Would Like to Subscribe to
              </h2>
              <UserList
                mergeList={mergeList}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
              />
            </>
          ) : (
            <div
              style={{
                textAlign: 'center',
                marginTop: '60px',
                marginBottom: '60px',
                fontWeight: 'bold',
              }}
            >
              Notifications are not ready for subscription. Please come back
              later.
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default User;
