export const msalConfig = (data) => {
  const auth = {
    auth: {
      clientId: data.REACT_APP_CLIENT_ID,
      authority: data.REACT_APP_LOGIN_URL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          // console.error(`${level} ${message}`);
        },
      },
    },
  };
  return auth;
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = (clientId) => {
  const scope = {
    scopes: [`api://${clientId}/AdminAccess`, `api://${clientId}/UserAccess`],
  };
  return scope;
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};
