import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import AppRouter from './routes';
import { getLoginScope } from './msalService';
import Loading from './components/Loading/Loading';

const App = () => {
  const loginScope = getLoginScope();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginScope);
  }

  if (inProgress !== InteractionStatus.None) {
    return <Loading />;
  }

  return (
    <>
      <AuthenticatedTemplate>
        <AppRouter />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
