import axios from 'axios';
import { configs } from './constants/configs';
import { loginRequest } from './authConfig';
const APICall = async (account, method, url, data = null) => {
  const loginScope = loginRequest(configs.REACT_APP_CLIENT_ID);
  const login = account.getActiveAccount();
  try {
    if (!account) {
      throw Error('No active account! Verify a user is signed in.');
    }

    const response = await account.acquireTokenSilent({
      ...loginScope,
      account: login,
    });

    const headers = {
      Authorization: `Bearer ${response.accessToken}`,
    };
    let axiosConfig;
    if (data) {
      axiosConfig = {
        method: method,
        url: url,
        headers: headers,
        data: data,
      };
    } else {
      axiosConfig = {
        method: method,
        url: url,
        headers: headers,
      };
    }

    return axios(axiosConfig);
  } catch (error) {
    console.error('Error acquiring token or making API call', error);
    throw error;
  }
};

export async function getApplication(account) {
  try {
    const response = await APICall(
      account,
      'get',
      `${configs.REACT_API_BASEURL}/applications`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function getNotification(account, id = 0) {
  try {
    const response = await APICall(
      account,
      'get',
      `${configs.REACT_API_BASEURL}/notification/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function getSubscription(account) {
  const { username } = account.getActiveAccount();
  try {
    const response = await APICall(
      account,
      'get',
      `${configs.REACT_API_BASEURL}/subscription/${username}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function postApplication(account, applicationData) {
  try {
    const response = await APICall(
      account,
      'post',
      `${configs.REACT_API_BASEURL}/application`,
      applicationData
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function postNotification(account, notificationData) {
  try {
    const response = await APICall(
      account,
      'post',
      `${configs.REACT_API_BASEURL}/notification`,
      notificationData
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function postSubscription(account, notificationUid) {
  const { username } = account.getActiveAccount();
  try {
    const response = await APICall(
      account,
      'post',
      `${configs.REACT_API_BASEURL}/subscription/${notificationUid}/${username}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function putNotification(account, notificationData) {
  try {
    const response = await APICall(
      account,
      'put',
      `${configs.REACT_API_BASEURL}/notification`,
      notificationData
    );
    return response.data;
  } catch (error) {
    console.log('error message:', error.message);
    throw error;
  }
}

export async function deleteNotification(account, id) {
  try {
    const response = await APICall(
      account,
      'delete',
      `${configs.REACT_API_BASEURL}/notification/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function deleteUserNotificationId(account, userNotificationId) {
  try {
    const response = await APICall(
      account,
      'delete',
      `${configs.REACT_API_BASEURL}/subscription/${userNotificationId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function deleteSubscription(account, notificationUId) {
  const { username } = account.getActiveAccount();
  try {
    const response = await APICall(
      account,
      'delete',
      `${configs.REACT_API_BASEURL}/subscription/${notificationUId}/${username}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

export async function deleteUserEmail(account, email) {
  try {
    const response = await APICall(
      account,
      'delete',
      `${configs.REACT_API_BASEURL}/subscription/${email}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}
