import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import User from './pages/User/User';
import Admin from './pages/Admin/Admin';
import Layout from './components/Layout.';
import Home from './pages/Home/Home';

const AppRouter = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/admin' element={<Admin />} />
          <Route exact path='/user' element={<User />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
