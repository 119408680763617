import React, { useEffect, useState } from 'react';
import axios from 'axios';
import App from './App.jsx';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig.js';
import { GetConfigFileName } from './Utility.js';
import { updateConfigs } from './constants/configs.js';
import Loading from './components/Loading/Loading.jsx';

const AppWithAuthentication = () => {
  const [msalInstance, setMsalInstance] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!msalInstance) {
        const configFileName = GetConfigFileName();
        try {
          const res = await axios.get(configFileName);
          updateConfigs(res.data);

          const instance = new PublicClientApplication(msalConfig(res.data));
          const accounts = instance.getAllAccounts();
          if (accounts && accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
          }
          instance.enableAccountStorageEvents();
          instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
              instance.setActiveAccount(event.payload.account);
            }
          });

          setMsalInstance(instance);
          setIsConfigLoaded(true);
        } catch (err) {
          console.error('Could not load application configuration.', err);
        }
      }
    })();
  }, [msalInstance]);

  return (
    <div>
      {isConfigLoaded ? (
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </React.StrictMode>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AppWithAuthentication;
