import './Home.css';
import { BsEnvelopeX } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='Home'>
      <h1 className='homeTitle'>Success!</h1>

      {/*Yet to figure out how to move the icon styling into the CSS file*/}
      <BsEnvelopeX
        className='icon'
        style={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        size='200px'
        color='#FF9E1F'
      />
      <h2 className='unsubscribe'> You have unsubscribed from: </h2>

      {/*Placeholder for Notification name*/}
      <p className='Notification'> NotificationName </p>
      <Link to='/user' style={{ textDecoration: 'none' }}>
        <button className='button'>Manage your Subscriptions </button>
      </Link>
      <Link to='/admin' style={{ textDecoration: 'none' }}>
        <button className='button'> Admin Settings </button>
      </Link>
    </div>
  );
};

export default Home;
